// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
//     $('.selectpicker').selectpicker('mobile');
// } 
(function ($) {
    $('.slider-bn').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        fade: true
    }); 
    // $('.slider-products').slick({
    //     dots: false,
    //     infinite: false,
    //     autoplay: true,
    //     speed: 1000,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     prevArrow: '<i class="prev slick-prev"><img src="assets/images/icon/icon-arrow.svg" alt=""></i>',
    //     nextArrow: '<i class="next slick-next"><img src="assets/images/icon/icon-arrow.svg" alt=""></i>',
    //     responsive: [{
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //
    //             }
    //         },
    //         {
    //             breakpoint: 786,
    //             settings: {
    //                 slidesToShow: 2
    //             }
    //         },
    //         {
    //             breakpoint: 414,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         }
    //     ]
    // });
    //

})(jQuery);

