AOS.init({
    once: true,
    duration: 1000
});
$("#nav a").on('click', function (event) {
    $('.navbar-collapse').removeClass('show');
    $('.navbar-toggler').addClass('collapsed').attr('aria-expanded', false);
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 300, function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
        });
    } // End if
});


// .scrollTop
jQuery(window).scroll(function (event) {
    var threshold = jQuery(document).height() - jQuery(window).height() - jQuery('.copyright').height();
    if (jQuery(window).scrollTop() > 0) {
        jQuery('.scrollToTop img').css({
            opacity: 1
        });
        if (jQuery(window).scrollTop() >= threshold) {
            jQuery('.scrollToTop').css({
                top: "-7.5rem",
                bottom: "auto",
                position: "absolute",
                right: "2rem",
                width: "6rem"
            });
        } else {
            jQuery('.scrollToTop').css({
                top: "auto",
                bottom: "15px",
                position: "fixed",
                right: "2rem",
                width: "6rem"
            });
        }
    } else {
        jQuery('.scrollToTop img').css({
            opacity: 0
        });
    }
});

jQuery('.scrollToTop').click(function () {
    jQuery("html, body").animate({
        scrollTop: 0
    }, 500, 'swing');
});

